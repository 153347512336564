import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "bulma/css/bulma.css"
import axios from "axios";
import Clipboard from "clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import isURL from "validator/lib/isURL";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

const SMOL_BASE = "smol.io";
const API_BASE = `https://${SMOL_BASE}/api/v1`;
const CAPTCHA_KEY = "6LeHiMYUAAAAAJLswJlHQfnmgmwUlzJ7lCQtUWyF";

class ShrinkBox extends React.Component {

  checkCaptcha(token) {
    this.setState(
      {captchaToken: token}
    )
  }

  checkTolLink(event) {
    let targetValue = event.target.value.trim();
    this.setState({
      tolValue: targetValue,
      validTolBoi: Boolean((targetValue && isURL(targetValue)))
    });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.shrink();
    }
  }

  shrink() {
    let targetValue = this.state.tolValue;
    let isValid = this.state.validTolBoi;

    if (targetValue && !(targetValue.includes("://"))) {
      targetValue = `https://${targetValue}`;
      isValid = isURL(targetValue);
      this.setState({
        tolValue: targetValue,
        validTolBoi: isValid
      });
    }

    if (isValid) {
      this.setState({isLoading: true})
      axios.post(`${API_BASE}/link`, { target: targetValue, token: this.state.captchaToken })
      .then(res => {
        this.setState({isLoading: false})
        if (res.status === 201 && res.data.id) {
          this.setState({
            tolValue: "",
            smolValue: `${SMOL_BASE}/${res.data.id}`,
            validTolBoi: false,
            warnings: ""
          });
        } else {
          this.setState({warnings: "Sorry, something went wrong :/"})
          console.error(res);
        }
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          warnings: "Sorry, something went wrong :/"
        })
        console.error(error);
      });
    } else {
      this.setState({warnings: "Sorry, that isn't a valid URL..."})
    }
  }

  constructor(props) {
    super(props);
    this.placeholder = `https://${SMOL_BASE}?im=tol&shrink=me`;
    let isLost = Boolean(this.props.query.im === "lost")

    this.state = {
      captchaToken: "",
      tolValue: "",
      smolValue: "",
      isLoading: false,
      warnings: isLost ? "Oops! Looks like you're lost!" : ""
    };

    this.checkTolLink = this.checkTolLink.bind(this);
    this.shrink = this.shrink.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  render() {
    return (
      <div>
        <div className={"notification has-text-centered is-warning " + (this.state.warnings ? "is-visible" : "is-hidden")}>{this.state.warnings}</div>
        <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
          <div className="field has-addons has-addons-centered">
            <GoogleReCaptcha onVerify={token => this.checkCaptcha(token)} />
            <div className={"control is-expanded " + (this.state.isLoading ? "is-disabled is-loading" : "")}>
              <input className="input" type="text" value={this.state.tolValue} onChange={this.checkTolLink} onKeyDown={this.handleKeyDown} placeholder={this.placeholder} />
            </div>
            <div className="control">
              <button className="button is-primary" 
                      onClick={this.shrink}>
                      shrink
              </button>
            </div>
          </div>
        </GoogleReCaptchaProvider>
        <div className={"space-top " + (this.state.smolValue ? "is-visible" : "is-hidden")}>
          <div className="field has-addons has-addons-centered">
            <div className="control">
              <input className="input" type="text" readOnly value={this.state.smolValue} />
            </div>
            <div className="control">
              <button className="button is-info copy-button" data-clipboard-text={this.state.smolValue}><FontAwesomeIcon icon={faCopy} /></button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    new Clipboard(".copy-button");
  }

}

class Home extends React.Component {

  render() {
    let query = queryString.parse(this.props.location.search)

    return (
      <div>
        <h3 className="space-top has-text-centered subtitle is-3">make any link smol</h3>
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <ShrinkBox query={query}/>
          </div>
        </div>
      </div>
    );
  }

}

function App() {
  return (
    <Router>
      <nav className="navbar is-dark is-fixed-top">
       <div className="navbar-brand space-left">
         <h1 className="title is-2 has-text-light">smol.io</h1>
        </div>
      </nav>
      <div className="section">
        <div className="container">
          <Route path="/" component={Home} />
        </div>
      </div>
    </Router>
  );
}

export default App;
